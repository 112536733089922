import request from './base/request';

const BASIC_UNIT = '/basic-unit';

const getListBasicUnit = (params) => {
  const body = new FormData();
  const payloadBlob = new Blob([JSON.stringify(params)], { type: 'application/json' });
  body.append('data', payloadBlob);
  body.append('_method', 'get');
  return request
    .post(BASIC_UNIT, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export { getListBasicUnit };
