import i18n from '@/lang/i18n';

export const CONDITIONLIST = [
  { value: '1', text: 'AND' },
  { value: '2', text: 'OR' },
  { value: '3', text: 'NOT' },
];

export const PAGE_SIZE_LIST = [
  {
    value: 20,
    text: i18n.t('basic_unit_library.per_page_item', { perpage: 20 }),
  },
  {
    value: 50,
    text: i18n.t('basic_unit_library.per_page_item', { perpage: 50 }),
  },
  {
    value: 100,
    text: i18n.t('basic_unit_library.per_page_item', { perpage: 100 }),
  },
];

export const DEFAULT_PAGESIZE = 1000;
export const DEFAULT_PAGING = {
  perPage: 1000,
  pageSize: 20,
};
