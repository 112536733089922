<template>
  <div class="page-title-text">
    <span>{{ title }}</span>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    }
  }
}
</script>
<style lang="scss" scoped>
.page-title-text {
  color: $goldMid;
  font-family: "Source Han Sans JP";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 1.1px;
}
</style>