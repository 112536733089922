import i18n from '@/lang/i18n.js'
import moment from 'moment';

export const getWidthOfLogHistory = () => {
  let width = 72
  switch (i18n.locale) {
    case 'en':
      width = 135
      break;
    case 'ar-AE':
      width = 110
      break;
    case 'es':
      width = 140
      break;
    case 'vi':
      width = 145
      break;
    default:
       width = 72
      break;
  }
  return width
}

export const getWidthOfDetailButton = () => {
  let width = 72
  switch (i18n.locale) {
    case 'en':
      width = 61
      break;
    case 'es':
      width = 75
      break;
    case 'ar-AE':
      width = 65
      break;
    case 'zh':
    case 'zh-TW':
      width = 75
      break;
      case 'vi':
      width = 90
      break;
    default:
       width = 55
      break;
  }
  return width
}

export const getWidthOfAttachedButton = () => {
  let width = 60
  switch (i18n.locale) {
    case 'en':
    case 'vi':
      width = 95
      break;
    case 'es':
      width = 125
      break;
    case 'ar-AE':
      width = 60
      break;
    case 'zh':
    case 'zh-TW':
      width = 65
      break;
    case 'vi':
        width = 90
        break;
    default:
       width = 60
      break;
  }
  return width
}


export const getWidthOfSearchButton = () => {
  let width = 82
  switch (i18n.locale) {
    case 'en':
      width = 160
      break;
    case 'es':
      width = 100
      break;
    case 'ar-AE':
      width = 100
      break;
    case 'zh':
    case 'zh-TW':
      width = 80
      break;
    case 'en':
      width = 105
      break;
    case 'vi':
      width = 180
      break
    default:
       width = 82
      break;
  }
  return width + 32; // 32 is tooltip
}

export const getWidthOfSmartLcaBtn = () => {
  let width = 82
  switch (i18n.locale) {
    case 'en':
      width = 147
      break;
    case 'es':
      width = 100
      break;
    case 'ar-AE':
      width = 100
      break;
    case 'zh':
    case 'zh-TW':
      width = 80
      break;
    case 'vi':
        width = 140
        break;
    default:
      width = 95
      break;
  }
  return width
}

export const getMinWidthOrganization = () => {
  let width = 118
  switch (i18n.locale) {
    case 'en':
      width = 240
      break;
    case 'es':
      width = 275
      break;
    case 'ar-AE':
      width = 160
      break;
    case 'vi':
      width = 176
      break;
    default:
       width = 118
      break;
  }
  return width
}

export const getMinWidthLastModified = () => {
  let width = 165
  switch (i18n.locale) {
    case 'es':
      width = 170
      break;
  
    default:
      width = 165
      break;
  }
  return width
}

export const getMinWidthViewerStatus = () => {
  let width = 170
  switch (i18n.locale) {
    case 'es':
      width = 220
      break;

    default:
      width = 170
      break;
  }
  return width
}

export const getMinWidthDirDateSource = () => { 
  let width = 83
  switch (i18n.locale) {
    case 'es':
      width = 110
      break;

    default:
      width = 83
      break;
  }
  return width
}

export const getWidthOfIlTotalAmount = () => {
  let width = 600
  switch (i18n.locale) {
    case 'en':
      width = 650
      break;
    default:
      width = 600
      break;
  }
  return width
}

export const getClassesMaxWidthActionTable = () => {
  let classes = ''
  switch (i18n.locale) {
    case 'en':
      classes = 'max-width-unset'
      break;
  
    default:
      classes = ''
      break;
  }
  return classes
}

export const getClassesFullScreenButton = () => {
  let classes = ''
  switch (i18n.locale) {
    case 'en':
      classes = 'width-fit-content'
      break;
      case 'vi':
      classes = 'width-fit-content-vi'
      break;
    default:
      classes = ''
      break;
  }
  return classes
}

export const isWordWrapTable = () => {
  switch (i18n.locale) {
    case 'en':
      return false;
  
    default:
      return true
  }
}

export const isEnglishLanguage = () => {
  switch (i18n.locale) {
    case 'en':
      return true;
  
    default:
      return false;
  }
}

export const convertMonthValueByMoment = (month) => {
  switch (i18n.locale) {
    case 'en':
      month = moment(month, 'MMM').month() + 1;
      break;
  
    default:
      month = moment(month, 'MM').format('M');
      break;
  }
  return month;
}

export const getClassesUnderline = (lang) => {
  let classes = 'underline';
  switch (lang) {
    case 'en':
      classes = 'underline_en';
      break;
    case 'ar-AE':
      classes = 'underline_ar';
      break;
    case 'zh-TW':
    case 'zh':
      classes = 'underline_zh';
      break;
      case 'vi':
        classes = 'underline_vi';
        break;
    default:
      classes = 'underline';
      break;
  }
  return classes;
}

export const getClassesFooter = (lang) => {
    let classes = ''
    switch (lang) {
      case 'vi':
        classes = 'footer-vi';
        break;
      default:
        classes = '';
        break;
    }
  return classes;
}

export const getWidthButtonByText = (content, styles = {}) => {
  const  { fontSize, fontWeight, letterSpacing, lineHeight } = styles;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = `${fontWeight} ${fontSize} Source Han Sans`;
  context.letterSpacing = letterSpacing;
  context.lineHeight = lineHeight;

  const width = Math.ceil(context.measureText(content).width) + 18; // 18 is padding

  return width <= 55 ? 55 : width; // 55 is default width
}
export const getWidthEditlButton = () => {
  let width = 72
  switch (i18n.locale) {
    case 'en':
      width = 56
      break;
    case 'es':
      width = 70
      break;
    case 'ar-AE':
      width = 60
      break;
    case 'zh':
    case 'zh-TW':
      width = 70
      break;
      case 'vi':
      width = 80
      break;
    default:
       width = 55
      break;
  }
  return width
}

export const getWidthByTextHeader = (string, styles, widthItemPlus) => {
  let styleText = {}
  let widthPlus = widthItemPlus ? widthItemPlus : 50
  if(styles) {
    styleText = styles
  } else {
    styleText = {
      fontSize: "13px",
      fontWeight: 500,
      letterSpacing: '0.04em',
    }
  }
  let text = document.createElement("span");
  document.body.appendChild(text);
  text.style.width = 'auto';
  text.style.position = 'absolute';
  text.style.whiteSpace = 'no-wrap';
  text.style.font = "Source Han Sans";
  text.style.fontSize = styleText.fontSize;
  text.style.fontWeight = styleText.fontWeight;
  text.style.letterSpacing = styleText.letterSpacing;

  text.innerHTML = string;
  const width = text.clientWidth;
  document.body.removeChild(text);
  return widthPlus + width;
};
