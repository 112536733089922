<template>
  <div class="input-field" :class="customizeClass">
    <v-text-field
      v-model="dataTitleModel"
      :placeholder="label"
      solo
      hide-details="auto"
      height="28"
      class="field"
			:disabled="isReadOnly"
      :error-messages="errorMessages"
			:class="{ 'read-only': isReadOnly, 'has-error': isError || errorMessages.length > 0, 'has-tooltip': isHasToolTip, 'is-focus': isFocus}"
      @focus="handleFocus"
      @change="handleChange"
      @blur="handleBlur"
      :id="idInputField"
      :tabindex="tabIndex"
    ></v-text-field>
    <img
      v-if="!isReadOnly && dataTitleModel"
      class="clear-data-input-search"
      :class="isTextAlignRight && 'text-align-input'"
      :src="iconClose"
      alt="close"
      @click="clearDataTitleModel"
      @mouseenter="isActiveCloseIcon = true"
      @mouseleave="isActiveCloseIcon = false"
    />
    <div class="input-field--tooltip" :class="{'has-tooltip': isHasToolTip}">{{ contentTooltip }}</div>
  </div>
</template>
<script>
import { formatValue, $_helper_isNumberType, $_helper_isNegativeNumber } from '@/concerns/newRegisterData/wijmo.helper';
import {addThoundSandComma} from '@/utils/convertNumber';
import { convertNestedFullWidthToHalfWidth } from '@/utils/convertHalfWidth'

export default {
  data() {
    return {
      isActiveCloseIcon: false,
      dataTitleModel: null,
      isFocus: false
    };
  },
  props: {
    inputValue: {
      type: [String, Number],
      default: null,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    isClearData: {
      type: Boolean,
      default: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isTextAlignRight: {
      type: Boolean,
      default: false,
    },
    isNumberType: {
      type: Boolean,
      default: false,
    },
    isHasToolTip: {
      type: Boolean,
      default: false,
    },
    contentTooltip: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    year: {
      type: Number,
      default: 0,
    },
    errorMessages: {
      type: [String, Array],
      default: ''
    },
    isPositiveNumber: {
      type: Boolean,
      default: false,
    },
    customizeClass: {
      type: String,
      default: '',
    },
    idInputField: {
      type: String,
      default: '',
    },
    tabIndex: {
      type: String,
      default: '1',
    },
    getOriginalValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    iconClose() {
      if (!this.isActiveCloseIcon) {
        if(this.isError || this.errorMessages.length > 0) {
          return require('@/assets/icons/product/input-error.svg');
        } else {
          return require('@/assets/icons/product/close-input.svg');
        }
      }
      return require('@/assets/icons/product/close-input-active.svg');
    }
  },
  methods: {
    clearDataTitleModel() {
      this.$emit('update:inputValue', '');
      this.dataTitleModel = ''
      this.isActiveCloseIcon = false
    },
    handleFocus() {
      if(this.isNumberType) {
        this.dataTitleModel = formatValue(this.dataTitleModel);
      }
      this.isFocus = true
      this.$emit('focus');
    },
    handleChange(value) {
      if (this.getOriginalValue) {
        this.$emit('change');
        return
      }
      const valueConvertToHalfWidth = convertNestedFullWidthToHalfWidth(value);
      this.dataTitleModel = valueConvertToHalfWidth;
      if ($_helper_isNumberType(valueConvertToHalfWidth) && this.isNumberType) {
        // this.dataTitleModel = valueConvertToHalfWidth?.toString()?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,'); // add , to after 3 numbers
        this.dataTitleModel = addThoundSandComma(valueConvertToHalfWidth?.toString())
      }
      if(!$_helper_isNumberType(valueConvertToHalfWidth) && this.isNumberType) {
        this.dataTitleModel =  ''
      }
      else if (
        this.isPositiveNumber &&
        $_helper_isNegativeNumber(valueConvertToHalfWidth)
      ) {
        this.dataTitleModel = '';
      }
      this.$emit('change');
    },
    handleBlur() {
      if (this.getOriginalValue) {
        this.isFocus = false
        return
      }
      if($_helper_isNumberType(this.dataTitleModel) && this.isNumberType) {
        // this.dataTitleModel = formatValue(this.dataTitleModel)?.substring(0, 20).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')
        const subStringRang = formatValue(this.dataTitleModel)?.includes('-') ? 26 : 25;
        this.dataTitleModel = addThoundSandComma(formatValue(this.dataTitleModel)?.substring(0, subStringRang))
      }
      this.isFocus = false
      this.$emit('blur', this.dataTitleModel)
      this.$emit('change');
      this.$emit('update:inputValue', this.dataTitleModel, this.index);
    }
  },
  mounted() {
    this.dataTitleModel = this.inputValue
    if(this.isNumberType) {
      this.handleBlur();
    }
  },
  watch: {
    inputValue() {
      this.dataTitleModel = this.inputValue
      if(!this.inputValue) {
        this.isActiveCloseIcon = false;
      }
    },
    dataTitleModel() {
      this.$emit('change');
      this.$emit('update:inputValue', this.dataTitleModel, this.index, this.year);
      this.$emit('input', this.dataTitleModel);
    }
  }
};
</script>
<style lang="scss" scoped>
.input-field {
  width: 100%;
  .field {
    .v-input__control {
      .v-input__slot {
        padding: 0 !important;
        .v-text-field__slot {
          border: 1px solid #e3eded;
          border-radius: 4px;
          line-height: 18px !important;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #404d50;
          height: 28px;

          input {
            padding: 12px;
            height: 28px;
          }

          label {
            padding: 0 12px;
            line-height: 18px !important;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #404d50;
          }
        }
      }
    }
  }

  .disabled_title {
    .v-input__control {
      pointer-events: none;

      .v-input__slot {
        .v-text-field__slot {
          input {
            background: $bgLight;
            border: 1px solid rgba(42, 42, 48, 0.1);
          }
        }
      }
    }
  }

  .v-input {
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.33px;
    color: $monoMid;

    .v-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }

    ::v-deep input {
      &::placeholder {
        font-size: 11px;
        font-weight: 700;
        line-height: 18px;
      }
    }
  }
}
.input-field {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 28px;
  background: $monoWhite;
  border: 1px solid $bgCusLight;
  box-shadow: inset 0px 2px 2px rgba(160, 181, 186, 0.15), inset 0px 2px 6px rgba(160, 181, 186, 0.5);
  border-radius: 4px;

	flex: none;
	order: 0;
	flex-grow: 1;
	// margin-bottom: 4px;
	position: relative;

  .clear-data-input-search {
    position: absolute;
    top: 7px;
    right: 11px;
    cursor: pointer;
    &.text-align-input {
      left: 11px;
    }
  }
  .input-field--tooltip {
    display: none;
  }
  &:hover {
    .has-tooltip {
      &.input-field--tooltip {
        display: block;
        position: absolute;
        top: -60px;
        width: max-content;
        max-width: 100%;
        height: auto;
        left: 0;
        border-radius: 4px;
        padding: 2px 10px 4px;
        background: #E0A329;
        color: #FFF;
        border: 1px solid #B28220;
        box-shadow: unset;
        white-space: pre-wrap;
      }
    }
  }
  &:focus-within {
    .has-tooltip {
      &.input-field--tooltip {
        display: none;
      }
    }
  }
}

::v-deep .v-input__slot {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  min-height: 28px;

  &::before {
    border-bottom: unset;
    display: none;
  }
  input {
    height: 18px;
    padding: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .input-field {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.field {
  &.has-error{
    ::v-deep .v-input__slot {
      border: 2px solid $redMid !important;
    }
    ::v-deep .error--text {
      margin-top: 4px;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 166.667% */
      letter-spacing: 0.6px
    }
  }
}
</style>
