<template>
  <div class="search-wrapper">
    <span class="search-title">{{ searchTitle }}</span>
    <div class="search-form">
      <div class="search-input">
        <InputFieldSmall v-if="isPaging" :isNumberType="isNumberType" :getOriginalValue="getOriginalValue" v-model="searchText"/>
        <InputField v-else :isNumberType="isNumberType" :getOriginalValue="getOriginalValue" v-model="searchText"/>
      </div>
      <CommonButton
        :label="buttonLabel"
        type="colored"
        @action="handleUpdateFilter()"
        :isDisable="isDisableButton"
        v-ripple="false"
      />
    </div>
  </div>
</template>
<script>
import InputField from '@/components/products/input/InputField.vue';
import InputFieldSmall from '@/components/products/input/InputFieldSmall.vue';
import CommonButton from '@/components/utils/button.vue';
export default {
  components: { InputField, CommonButton, InputFieldSmall },
  props: {
    searchTitle: {
      type: String,
      default: '',
    },
    isDisableButton: {
      type: Boolean,
      default: false,
    },
    isNumberType: {
      type: Boolean,
      default: false
    },
    getOriginalValue: {
      type: Boolean,
      default: false
    },
    isPaging: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchText: null,
    }
  },
  computed: {
    buttonLabel() {
      return this.isPaging ? this.$t("basic_unit_library.label_paging_button") : this.$t('register_data.button_search')
    }
  },
  methods: {
    handleUpdateFilter() {
      this.$emit('handleSeachFilter', this.searchText);
    }
  },
  watch: {
    searchText: {
      handler(value) {
        this.$emit('handleDisableSearchBtn', value);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search-wrapper {
  display: flex;
  flex-flow: column;
  gap: 8px;
  height: fit-content;
  .search-title {
    color: $monoBlack;
    font-family: "Source Han Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.42px;
  }
  .search-form {
    display: flex;
    flex-flow: row;
    gap: 8px;
    .search-input {
      width: 100%;
      ::v-deep .input-field {
        .field.is-focus {
          .v-input__control {
            .v-input__slot {
              border: unset !important;
              outline: 2px solid $blueMid;
            }
          }
        }
      } 
    }
  }
}
</style>