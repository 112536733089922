import request from './base/request';
const IDEA_DB_SOURCE_SERVICE = '/db-source';
import { prepareMasterOrCustomizeDB } from '@/concerns/utils/master-or-customize-db';
import { DB_TYPE } from '@/constants/dbType';
export var listAllDbIdea = []
export var dbIdeaObj = {}
export var dbIdeaBySourceName = {}

export const getListIdeaDbSourceDetailsApi = () => {
  return request
    .get(IDEA_DB_SOURCE_SERVICE)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};


export const getIdeaDbSourceDetailsApi = (id) => {
    return request
      .get(`${IDEA_DB_SOURCE_SERVICE}/details/?id=${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

export const getAllDbSource = (data) => {
  return request
    .get(`/db-source/details`, data)
    .then((res) => {
      listAllDbIdea = res.db_source_detail
      listAllDbIdea.forEach((dbIdea) => {
        if(!dbIdeaBySourceName[dbIdea?.source]) {
          dbIdeaBySourceName[dbIdea?.source] = {...dbIdea, type: DB_TYPE.IDEA };
        }
      })
      let result = {}
      prepareMasterOrCustomizeDB(listAllDbIdea.map(item => {
        return { ...item, type: 2}
      })).forEach((item) => {
        if (!result[item.id]) {
          result[item.id] = {...item}
        }
      });
      dbIdeaObj = result
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateHistoryCurrentUser = (data) => {
  return request
    .put(`${IDEA_DB_SOURCE_SERVICE}/history-current-user`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

